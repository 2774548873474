<template>
    <painel titulo="CRM - Checkout - Demanda Express" icone="pi pi-comments" :refreshFunction="obterDemandaExpress">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :demandaExpress="demandaExpress"></dados-gerais>
                </TabPanel>
                <TabPanel header="Dados Recebidos">
                    <vue-json-pretty
                        v-if="demandaExpress?.dadosRecebidos"
                        :data="JSON.parse(demandaExpress?.dadosRecebidos)"
                        :showDoubleQuotes="true"
                    ></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Objeto Integração" v-if="demandaExpress?.integracaoObjeto">
                    <vue-json-pretty :data="JSON.parse(demandaExpress?.integracaoObjeto)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Retorno Integração" v-if="demandaExpress?.integracaoRetorno">
                    <vue-json-pretty :data="JSON.parse(demandaExpress?.integracaoRetorno)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'CRM_Checkout_DemandasExpress' }"></btn-voltar>
    </painel>
</template>

<script>
import DemandasExpressService from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        DadosGerais,
        VueJsonPretty,
    },

    data() {
        return {
            demandaExpress: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterDemandaExpress() {
            this.$store.dispatch('addRequest');
            DemandasExpressService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.demandaExpress = response.data;
                } else {
                    this.demandaExpress = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterDemandaExpress();
    },
};
</script>
